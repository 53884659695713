(function (angular) {
    angular
        .module('one.admin')
        .config(config);

    config.$inject = ['$stateProvider', 'config', 'modalProvider'];

    function config($stateProvider, config, modalProvider) {
        $stateProvider.state('crm', {
            parent: 'main',
            abstract: true,
            url: '/crm',
            template: '<div ui-view></div>',
            params: {
                section: 'crm'
            }
        });

        // Contacts
        $stateProvider.state('crm.accounts', {
            parent: 'crm',
            abstract: true,
            url: '/contacts',
            template: '<div ui-view></div>',
            params: {
                section: 'accounts'
            }
        });

        $stateProvider.state('crm.accounts.index', {
            url: '',
            controller: 'AccountsController',
            templateUrl: config.path.template('accounts.index'),
            requirePermission: 'accounts.overview',
            resolve: {
                types: ['AccountService', '$stateParams', function (AccountService) {
                    return AccountService.types();
                }]
            }
        });

        $stateProvider.state('crm.accounts.account', {
            url: '/:accountId',
            abstract: true,
            controller: 'AccountController',
            templateUrl: config.path.template('accounts.account'),
            resolve: {
                account: ['AccountService', '$stateParams', function (AccountService, $stateParams) {
                    return AccountService.account($stateParams.accountId);
                }]
            }
        });

        $stateProvider.state('crm.accounts.account.index', {
            url: '',
            controller: 'AccountAboutController',
            templateUrl: config.path.template('accounts.account.about_tab'),
            requirePermission: 'accounts.view',
            resolve: {
                account: ['AccountService', '$stateParams', function (AccountService, $stateParams) {
                    return AccountService.account($stateParams.accountId);
                }],
                genders: ['AccountService', function (AccountService) {
                    return AccountService.genders();
                }],
                activity: ['AccountService', '$stateParams', function (AccountService, $stateParams) {
                    return AccountService.account.activity($stateParams.accountId);
                }],
                sites: ['SiteService', function (SiteService) {
                    return SiteService.sites();
                }],
                languages: ['SiteService', function (SiteService) {
                    return SiteService.languages();
                }]
            }
        });

        $stateProvider.state('crm.accounts.account.communication', {
            url: '/communication',
            controller: 'AccountCommunicationController',
            templateUrl: config.path.template('accounts.account.communication_tab'),
            requirePermission: 'accounts.view'
        });

        $stateProvider.state('crm.accounts.account.formEntries', {
            url: '/form-entries',
            controller: 'AccountFormEntriesController',
            templateUrl: config.path.template('accounts.account.form_entries_tab'),
            requirePermission: 'accounts.view'
        });

        $stateProvider.state('crm.accounts.account.actionCampaigns', {
            url: '/campaigns',
            controller: 'AccountActionCampaignsController',
            templateUrl: config.path.template('accounts.account.action_campaigns_tab'),
            requirePermission: 'accounts.view'
        });

        $stateProvider.state('crm.accounts.account.orders', {
            url: '/orders',
            controller: 'AccountOrdersController',
            templateUrl: config.path.template('accounts.account.orders_tab'),
            requirePermission: 'accounts.view'
        });

        $stateProvider.state('crm.accounts.account.gifts', {
            url: '/gifts',
            controller: 'AccountGiftsController',
            templateUrl: config.path.template('accounts.account.gifts_tab'),
            requirePermission: 'accounts.view'
        });

        $stateProvider.state('crm.accounts.account.financial', {
            url: '/financial',
            controller: 'AccountFinancialController',
            templateUrl: config.path.template('accounts.account.financial_tab'),
            requirePermission: 'accounts.view'
        });

        $stateProvider.state('crm.accounts.account.attachments', {
            url: '/attachments',
            controller: 'AccountAttachmentsController',
            templateUrl: config.path.template('accounts.account.attachments_tab'),
            requirePermission: 'accounts.view'
        });

        modalProvider.modal('editAccountContactUser', {
            templateUrl: config.path.template('accounts.account.contact_user.edit_contact_user_modal'),
            controller: 'EditAccountContactUserModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                users: ['AuthService', function (AuthService) {
                    return AuthService.users();
                }]
            }
        });

        modalProvider.modal('addAccount', {
            templateUrl: config.path.template('accounts.account.add_account_modal'),
            controller: 'AddAccountModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                genders: ['AccountService', function (AccountService) {
                    return AccountService.genders();
                }]
            }
        });

        modalProvider.modal('deleteAccount', {
            templateUrl: config.path.template('accounts.account.delete_modal'),
            controller: 'DeleteAccountModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('mergeAccounts', {
            templateUrl: config.path.template('accounts.account.merge_modal'),
            controller: 'MergeAccountsModalController',
            backdrop: 'static',
            keyboard: false
        });

        // Duplicates
        $stateProvider.state('crm.duplicates', {
            parent: 'crm',
            abstract: true,
            url: '/duplicates',
            template: '<div ui-view></div>',
            params: {
                section: 'accounts'
            }
        });

        $stateProvider.state('crm.duplicates.index', {
            url: '',
            controller: 'DuplicatesController',
            templateUrl: config.path.template('duplicates.index'),
            requirePermission: 'duplicates.overview'
        });

        $stateProvider.state('crm.duplicates.account', {
            url: '/:accountId',
            controller: 'AccountDuplicatesController',
            templateUrl: config.path.template('duplicates.account'),
            resolve: {
                account: ['AccountService', '$stateParams', function (AccountService, $stateParams) {
                    return AccountService.account($stateParams.accountId);
                }]
            },
            requirePermission: 'duplicates.overview'
        });

        // Interactions
        $stateProvider.state('crm.interactions', {
            parent: 'crm',
            abstract: true,
            url: '/interactions',
            template: '<div ui-view></div>',
            params: {
                section: 'accounts'
            }
        });

        $stateProvider.state('crm.interactions.index', {
            url: '/my-to-dos',
            controller: 'InteractionsController',
            templateUrl: config.path.template('interactions.index'),
            requirePermission: 'interactions.overview'
        });

        $stateProvider.state('crm.interactions.interactionSubjects', {
            parent: 'crm.interactions',
            abstract: true,
            url: '/subjects',
            template: '<div ui-view></div>'
        });

        $stateProvider.state('crm.interactions.interactionSubjects.index', {
            url: '',
            controller: 'InteractionSubjectsController',
            templateUrl: config.path.template('interaction_subjects.index'),
            resolve: {},
            requirePermission: 'products.overview'
        });

        $stateProvider.state('crm.interactions.interactionSubjects.editInteractionSubject', {
            url: '/:interactionSubjectId/edit',
            controller: 'EditInteractionSubjectController',
            templateUrl: config.path.template('interaction_subjects.edit'),
            resolve: {
                subject: ['InteractionService', '$stateParams', function (InteractionService, $stateParams) {
                    return InteractionService.subject($stateParams.interactionSubjectId);
                }]
            },
            requirePermission: 'interaction_subjects.edit'
        });

        $stateProvider.state('crm.interactions.all', {
            url: '/all',
            controller: 'InteractionsController',
            templateUrl: config.path.template('interactions.all'),
            requirePermission: 'interactions.overview'
        });

        $stateProvider.state('crm.interactions.editInteraction', {
            url: '/:interactionId/edit',
            controller: 'EditInteractionController',
            templateUrl: config.path.template('interactions.edit'),
            resolve: {
                interaction: ['InteractionService', '$stateParams', function (InteractionService, $stateParams) {
                    return InteractionService.interaction($stateParams.interactionId);
                }],
                subjects: ['InteractionService', function (InteractionService) {
                    return InteractionService.subjects();
                }],
                users: ['AuthService', function (AuthService) {
                    return AuthService.users();
                }],
                notificationMails: ['InteractionService', function (InteractionService) {
                    return InteractionService.notificationMails();
                }],
            },
            requirePermission: 'interactions.edit'
        });

        modalProvider.modal('interaction', {
            templateUrl: config.path.template('interactions.interaction'),
            controller: 'InteractionModalController'
        });

        modalProvider.modal('addInteraction', {
            templateUrl: config.path.template('interactions.add'),
            controller: 'AddInteractionModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                subjects: ['InteractionService', function (InteractionService) {
                    return InteractionService.subjects();
                }],
                users: ['AuthService', function (AuthService) {
                    return AuthService.users();
                }],
                notificationMails: ['InteractionService', function (InteractionService) {
                    return InteractionService.notificationMails();
                }],
            }
        });

        modalProvider.modal('addInteractionSubject', {
            templateUrl: config.path.template('interaction_subjects.add'),
            controller: 'AddInteractionSubjectModalController',
        });

        modalProvider.modal('interactionsCalendar', {
            templateUrl: config.path.template('interactions.calendar'),
            controller: 'InteractionsCalendarModalController',
            resolve: {
                calendarUrl: ['InteractionService', function (InteractionService) {
                    return InteractionService.calendarUrl();
                }]
            }
        });

        // Forms
        $stateProvider.state('crm.forms', {
            parent: 'crm',
            abstract: true,
            url: '/forms',
            template: '<div ui-view></div>',
            params: {
                section: 'accounts'
            }
        });

        $stateProvider.state('crm.forms.index', {
            url: '',
            controller: 'FormsController',
            templateUrl: config.path.template('forms.index'),
            resolve: {},
            requirePermission: 'forms.overview'
        });

        $stateProvider.state('crm.forms.formEntry', {
            url: '/entries/:formEntryId',
            controller: 'FormEntryController',
            templateUrl: config.path.template('forms.entries.entry'),
            resolve: {
                formEntry: ['FormEntryService', '$stateParams', function (FormEntryService, $stateParams) {
                    return FormEntryService.entry($stateParams.formEntryId);
                }]
            },
            requirePermission: 'form_entries.view'
        });

        $stateProvider.state('crm.forms.editFormEntry', {
            url: '/:formId/entries/:formEntryId/edit',
            controller: 'EditFormEntryController',
            templateUrl: config.path.template('forms.entries.edit'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }],
                formEntry: ['FormEntryService', '$stateParams', function (FormEntryService, $stateParams) {
                    return FormEntryService.entry($stateParams.formEntryId);
                }]
            },
            requirePermission: 'form_entries.edit'
        });

        $stateProvider.state('crm.forms.formOverview', {
            url: '/:formId/overview',
            controller: 'FormOverviewController',
            templateUrl: config.path.template('forms.overview'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }],
                visitablePages: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form.visitablePages($stateParams.formId);
                }]
            },
            requirePermission: 'forms.view'
        });

        $stateProvider.state('crm.forms.formStructure', {
            url: '/:formId/structure',
            controller: 'FormStructureController',
            templateUrl: config.path.template('forms.structure.visible'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }]
            },
            requirePermission: 'forms.view'
        });

        $stateProvider.state('crm.forms.hiddenFormStructure', {
            url: '/:formId/structure/hidden',
            controller: 'HiddenFormStructureController',
            templateUrl: config.path.template('forms.structure.hidden'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }]
            },
            requirePermission: 'forms.view'
        });

        $stateProvider.state('crm.forms.disabledFormStructure', {
            url: '/:formId/structure/disabled',
            controller: 'DisabledFormStructureController',
            templateUrl: config.path.template('forms.structure.disabled'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }]
            },
            requirePermission: 'forms.view'
        });

        $stateProvider.state('crm.forms.formEntries', {
            url: '/:formId/entries',
            controller: 'FormEntriesController',
            templateUrl: config.path.template('forms.entries'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }],
                visitablePages: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form.visitablePages($stateParams.formId);
                }]
            },
            requirePermission: 'forms.view'
        });

        $stateProvider.state('crm.forms.formDistribution', {
            url: '/:formId/distribution',
            controller: 'FormDistributionController',
            templateUrl: config.path.template('forms.distribution'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }],
                visitablePages: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form.visitablePages($stateParams.formId);
                }]
            },
            requirePermission: 'forms.view'
        });

        $stateProvider.state('crm.forms.editForm', {
            url: '/:formId/edit',
            controller: 'EditFormController',
            templateUrl: config.path.template('forms.edit'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }]
            },
            requirePermission: 'forms.edit'
        });

        $stateProvider.state('crm.forms.editFormPage', {
            url: '/:formId/pages/:formPageId/edit',
            controller: 'EditFormPageController',
            templateUrl: config.path.template('forms.pages.edit'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }],
                page: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.page($stateParams.formPageId);
                }]
            },
            requirePermission: 'forms.edit'
        });

        modalProvider.modal('addFormField', {
            templateUrl: config.path.template('forms.fields.add'),
            controller: 'AddFormFieldModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addFormPage', {
            templateUrl: config.path.template('forms.pages.add'),
            controller: 'AddFormPageModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {}
        });

        modalProvider.modal('sendFormEntryConfirmationMail', {
            templateUrl: config.path.template('forms.entries.send_confirmation_mail_modal'),
            controller: 'SendFormEntryConfirmationMailModalController',
            resolve: {}
        });

        $stateProvider.state('crm.forms.formField', {
            url: '/:formId/fields/:formFieldId',
            controller: 'FormFieldController',
            templateUrl: config.path.template('forms.fields.field'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }],
                field: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.field($stateParams.formFieldId);
                }]
            },
            requirePermission: 'forms.edit'
        });

        $stateProvider.state('crm.forms.editFormField', {
            url: '/:formId/fields/:formFieldId/edit',
            controller: 'EditFormFieldController',
            templateUrl: config.path.template('forms.fields.edit'),
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }],
                field: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.field($stateParams.formFieldId);
                }]
            },
            requirePermission: 'forms.edit'
        });

        $stateProvider.state('crm.forms.addFormEntry', {
            url: '/:formId/entries/add',
            controller: 'AddFormEntryController',
            templateUrl: config.path.template('forms.entries.add'),
            params: {
                account: null
            },
            resolve: {
                form: ['FormService', '$stateParams', function (FormService, $stateParams) {
                    return FormService.form($stateParams.formId);
                }]
            },
            requirePermission: 'form_entries.add'
        });

        modalProvider.modal('addForm', {
            templateUrl: config.path.template('forms.add'),
            controller: 'AddFormModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {}
        });

        modalProvider.modal('selectFormEntry', {
            templateUrl: config.path.template('forms.entries.form_picker'),
            controller: 'SelectFormEntryModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                forms: ['FormService', function (FormService) {
                    return FormService.forms({ filters: { root: true } });
                }]
            }
        });

        modalProvider.modal('publishForm', {
            templateUrl: config.path.template('forms.publish'),
            controller: 'PublishFormModalController',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                sites: ['SiteService', function (SiteService) {
                    return SiteService.sites();
                }]
            }
        });

        // Groups
        $stateProvider.state('crm.groups', {
            parent: 'crm',
            abstract: true,
            url: '/groups',
            template: '<div ui-view></div>',
            params: {
                section: 'accounts'
            }
        });

        $stateProvider.state('crm.groups.index', {
            url: '',
            controller: 'GroupsController',
            templateUrl: config.path.template('groups.index'),
            requirePermission: 'groups.overview'
        });

        $stateProvider.state('crm.groups.group', {
            url: '/:groupId',
            controller: 'GroupController',
            templateUrl: config.path.template('groups.group'),
            resolve: {
                group: ['GroupService', '$stateParams', function (GroupService, $stateParams) {
                    return GroupService.group($stateParams.groupId);
                }]
            },
            requirePermission: 'groups.view'
        });

        $stateProvider.state('crm.groups.editGroup', {
            url: '/:groupId/edit',
            controller: 'EditGroupController',
            templateUrl: config.path.template('groups.edit'),
            resolve: {
                group: ['GroupService', '$stateParams', function (GroupService, $stateParams) {
                    return GroupService.group($stateParams.groupId);
                }]
            },
            requirePermission: 'groups.edit'
        });

        modalProvider.modal('addGroup', {
            templateUrl: config.path.template('groups.add'),
            controller: 'AddGroupModalController',
            backdrop: 'static',
            keyboard: false
        });

        modalProvider.modal('addGroupMembership', {
            templateUrl: config.path.template('groups.memberships.add'),
            controller: 'AddGroupMembershipModalController',
            backdrop: 'static',
            keyboard: false,
        });

        // Relations
        $stateProvider.state('crm.relations', {
            parent: 'settings',
            abstract: true,
            url: '/relations',
            template: '<div ui-view></div>',
            params: {
                section: 'accounts'
            }
        });

        // Segments
        $stateProvider.state('crm.segments', {
            parent: 'crm',
            abstract: true,
            url: '/segments',
            template: '<div ui-view></div>',
            params: {
                section: 'accounts'
            }
        });

        $stateProvider.state('crm.segments.index', {
            url: '',
            controller: 'SegmentsController',
            templateUrl: config.path.template('segments.index'),
            requirePermission: 'segments.overview'
        });

        $stateProvider.state('crm.segments.addSegment', {
            url: '/add',
            controller: 'AddSegmentController',
            templateUrl: config.path.template('segments.add'),
            requirePermission: 'segments.add'
        });

        $stateProvider.state('crm.segments.segment', {
            url: '/:segmentId',
            controller: 'SegmentController',
            templateUrl: config.path.template('segments.segment'),
            resolve: {
                segment: ['SegmentService', '$stateParams', function (SegmentService, $stateParams) {
                    return SegmentService.segment($stateParams.segmentId);
                }]
            },
            requirePermission: 'segments.view'
        });

        $stateProvider.state('crm.segments.editSegment', {
            url: '/:segmentId/edit',
            controller: 'EditSegmentController',
            templateUrl: config.path.template('segments.edit'),
            resolve: {
                segment: ['SegmentService', '$stateParams', function (SegmentService, $stateParams) {
                    return SegmentService.segment($stateParams.segmentId);
                }]
            },
            requirePermission: 'segments.edit'
        });

        modalProvider.modal('diffSegment', {
            templateUrl: config.path.template('segments.diff'),
            controller: 'DiffSegmentModalController',
            backdrop: 'static',
            keyboard: false
        });
    }
})(angular);
